import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import MetaTags from "react-meta-tags";

// Import Images
import logodark from "../../assets/images/onboardingkit-logo-large-transparent.png";
import logolight from "../../assets/images/onboardingkit-logo-large-transparent.png";
import comingsoon from "../../assets/images/coming-soon.png";

//Import Countdown
import Countdown from "react-countdown";
import { Link } from 'react-router-dom';

const ComingSoonPage = () => {

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <div id="countdown" className="countdownlist"><div className="countdown-endtxt"></div></div>
        } else {
            return (
                <>
                    <div className="countdownlist-item">
                        <div className="count-title">Days</div>
                        <div className="count-num">
                            {days}
                        </div>
                    </div>{" "}
                    <div className="countdownlist-item">
                        <div className="count-title">Hours</div>
                        <div className="count-num">
                            {hours}
                        </div>
                    </div>{" "}
                    <div className="countdownlist-item">
                        <div className="count-title">Minutes</div>
                        <div className="count-num">
                            {minutes}
                        </div>
                    </div>{" "}
                    <div className="countdownlist-item">
                        <div className="count-title">Seconds</div>
                        <div className="count-num">
                            {seconds}
                        </div>
                    </div>
                </>
            )
        }
    }

    return (
        <React.Fragment>
            <MetaTags>
                <title>OnboardingKit | Launching Soon</title>
            </MetaTags>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-white"><i className="fas fa-home h3 text-primary"></i></Link>
            </div>

            <div className="authentication-bg min-vh-100 py-5 py-lg-0">
                <div className="bg-overlay bg-light"></div>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center">
                                
                                <Link to="/" className="d-block auth-logo">
                                    <img src={logodark} alt="" height="100" className="auth-logo-dark mx-auto" />
                                    <img src={logolight} alt="" height="100" className="auth-logo-light mx-auto" />
                                </Link>

                                <Row className="justify-content-center mt-5">
                                    <Col sm={6}>
                                        <div className="maintenance-img">
                                            <img src={comingsoon} alt="" className="img-fluid mx-auto d-block" />
                                        </div>
                                    </Col>
                                </Row>
                                <h4 className="mt-5">Launching Soon</h4>

                                <Row className="justify-content-center mt-5">
                                    <Col md={9}>
                                        <div id="countdown" className="countdownlist">

                                            <Countdown date="2022/07/04" renderer={renderer} />

                                        </div>
                                    </Col>
                                </Row>

                                <div className="input-group countdown-input-group mx-auto my-5">
                                    <input type="email" className="form-control border-light shadow" placeholder="Enter your email address to be notified" aria-label="search result" aria-describedby="button-email" />
                                    <button className="btn btn-primary" type="button" id="button-email"><i className="bx bx-paper-plane ms-1"></i></button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ComingSoonPage;